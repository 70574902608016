<template>
  <div class="content">
    <div class="container-fluid">
      <div class="row">
        <div class="col-lg-10 offset-lg-1 p-0">
          <card>
            <div slot="header" class="ml-2">
                <h4 class="text-uppercase mt-2 mb-1 text-secondary" >{{$t("solicitud_documentos")}}</h4>
            </div>
        
            <div class="row mb-5 ">        
                <div class="col-lg-10 pl-3 ">
                    <div class="mb-4">
                      <p class="mt-0 mb-2 text-info font-weight-bold ml-2">{{$t("TemplateData")}}</p>
                      <hr class="border-top mt-0 border-info">
                    </div>
                    <div>
                        <base-input type="text" :label="$t('Nombre')"  autocomplete="false" v-model="name">
                            <template  v-slot:textError v-if="$v.name.$error && !$v.name.required" class="mb-1 invalid-feedback">
                                  {{$t("Campo_requerido")}}
                            </template>
                        </base-input>  
                        <div class="row ">
                          <div class="col-md-1 col-2 d-flex  justify-content-center px-0">
                              <div class="form-group">
                                <div class="col-12 px-0">
                                  <label class="control-label">
                                    {{$t("Grupo")}} 
                                  </label>
                                </div>
                                <div class="ml-2">
                                  <base-checkbox v-model="isIndex" class="" :checked="isIndex" value="true"> </base-checkbox>
                                </div>
                              </div>
                            </div>
                            <div class="col-md-9 col-10">
                              <base-input type="text" :label="$t('item_request')"  autocomplete="false" v-model="item_request"/>
                            </div>
                            <div class="col-md-2 col-12 col-sm-4 d-flex align-items-center">
                                <div class="w-100">
                                  <button class="btn btn-info btn-fill w-100 mt-2" @click="onAddRequest">
                                      {{$t("add")}}
                                  </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>  
                <div class="col-lg-10  pl-3 mb-3" >
                  <draggable
                  :list="request_items"
                  :disabled="!enabled"
                  class="list-group"
                  ghost-class="ghost"
                  @start="dragging = true"
                  @end="dragging = false"
                >
                  <div class="list-group-item d-flex justify-content-between align-items-center px-3 py-1"  v-for="(element,index) in request_items" :key="index" v-bind:class="{'bg-gray titleColor' : element.isIndex}">
                    <span>{{ element.name }}</span>
                    <div>
                        <button class="btn border-0 text-danger py-1" @click="deleteEvent(element,index)">                            
                          <i class="fas fa-times-circle"></i>
                      </button>
                    </div>
                  </div>
                </draggable>                  
                </div>
                <div class="col-12 d-flex justify-content-end ">
                    <button class="btn btn-dark btn-fill px-4" @click="submit"> 
                        <i class="fa fa-check"></i>
                        {{$t("Guardar")}}
                    </button>
                </div>                   
            </div>
        </card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { apiCallSingle } from '../../mixins';
import { required} from 'vuelidate/lib/validators';
  import draggable from 'vuedraggable'

  export default {
    mixins: [apiCallSingle],
    data () {
      return {
        endpoint  : "template_request_documentation",      
        section   : "admin/solicitud_doc", 
        name    : null,
        request_items : [],
        item_request : null,
        columnsRequest  : ["name","delete_event"],
        enabled: true,
        isIndex : false,
        dragging: false
      }
    },
     components: {
          draggable,
      },
    props : {
        id : String
    },
    validations(){
        return {
            name : {
                required
            }
        }
    },
    methods : {
  
    
      loadData(){
            this.name   = this.data.name;
            this.request_items = this.data.items;
            
      },
      getProperties(){
            return {
              name  : this.name,
              request_template : this.request_items
          }
      },
      deleteEvent(item,index){
        this.request_items.splice(index,1);
      },
      onAddRequest(){
        if(this.item_request){
          this.request_items.push({
            name : this.item_request,
            isIndex : this.isIndex
          });
          this.item_request = null;
          this.isIndex = false;
        }
      }

    }
}
</script>

<style >
 .text-blue {
    color: #007bff !important;
}
.bg-gray{
  background: #f0f0f0;
}
.buttons {
  margin-top: 35px;
}
.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
.titleColor{
  color : #6674c3;
}
</style>